import "../styles/views/homepage.scss";

import React, { Component } from 'react';
import { REVIEWS } from "../utils";
import Header from '../components/Header'
import Footer from '../components/Footer'
import CookiePopup from '../components/CookiePopup'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import PlateInput from "../components/PlateInput";
import FAQ from "../components/FAQ";

import '@material/web/button/outlined-button.js';

const QUESTIONS_DEFAULT = [
    ["Do I need to buy a full car check?", "1 in 4 vehicles have a hidden past. If you ask us, it’s better not to leave it to chance when buying a used car. Completing a full vehicle check using your reg number means that you get instant peace of mind that a vehicle is safe and in good condition. And at only £4.99, it won’t break the bank!"],
    ["Do you do a free check?", "Yes, we do! Our free car check includes limited information about the vehicle. Here we provide an MOT and tax check, as well as an exported check and if there are any recalls. Additionally, the vehicle's MOT history will be provided."],
    ["How is this car check service different to the GOV.UK service?", "Auto Reg Check compiles much more data than the GOV.UK service, into a user-friendly vehicle check report that can be digested at your own pace. We endeavour to provide you with as much information as possible, so you can make an informed decision about buying a used car."],
    ["How is data compiled into your reports?", "Auto Reg Check has been designed to be an automatic vehicle check service. When you run a number plate check with our system, we automatically run a query of our databases that can return more than 800 data points about a vehicle. We then compile these data points into a structured report for you to browse."],
    ["In which formats can I receive my report?", "Your Auto Reg Check report is a web application that will be made available as soon as your purchase is complete and the vehicle check has been completed. This means that you will need a working internet connection to view your car check report if you wish to return to it at any time. The web link will also be emailed to you upon purchase. We are working on providing our reports in PDF format."],
    ["Can a car check report act as a conclusive indicator of a vehicle's quality and condition?", "The Auto Reg Check report provides vehicle data from official sources and presents it so that a customer can make an informed decision about the quality of a vehicle. We do not provide any suggestions or guidance on whether you should or shouldn’t buy a vehicle, that is entirely up to the customer."],
    ["Does an Auto Reg Check report cover all road incidents and significant events?", "We use data from official sources. If an accident has occurred and the official database has a record of it, then it will appear in your car check report. This goes for all of the official databases that we use. Unfortunately, there are cases of improper conduct with regards to vehicle repair and resale which mean official databases are not notified of an event. For example, if a car is written off and repaired without the insurance company being notified, then there will be no record in the official database and it will therefore not show up in an Auto Reg Check report."],
    ["How can I find when my tax and MOT is due?", "Enter your reg number into the number plate at the top of this page, wait for the free car check report to load, then navigate to the MOT section. Here you can check the MOT and tax expiry date of the vehicle."],
    ["How can I check the MOT history of a vehicle?", "Our free car check carries out a basic MOT check into a vehicle. By entering your number plate, you can quickly see the last MOT test result for the vehicle. To unlock the full MOT history, you will need to purchase a full vehicle check for £4.99."],
]

const QUESTIONS_MOT = [
    ["Should I buy a full car check?", "25% of vehicles have hidden histories. We recommend not leaving it to chance when purchasing a used car. A full vehicle check using your registration number provides immediate peace of mind that a vehicle is safe and in good shape. At just £4.99, it’s also very affordable!"],
    ["Is there a free MOT check?", "Yes! Our free MOT check offers limited information. We provide the MOT and tax status of the vehicle, along with the last MOT test result."],
    ["How does this service differ from GOV.UK?", "Auto Reg Check gathers more data than the GOV.UK service, presenting it in a user-friendly MOT check report that you can review at your leisure. We aim to provide comprehensive information to help you make an informed purchase."],
    ["How is data compiled in your MOT check reports?", "Auto Reg Check is an automated vehicle check service. When you perform a number plate check, our system queries databases returning over 800 data points. We then organise these points into a structured, browsable report."],
    ["In what formats can I get my MOT check report?", "Your Auto Reg Check report is a web application, available immediately after purchase and completion of the vehicle check. You need an internet connection to view it anytime. The web link will be emailed to you. We are also working on providing PDF format."],
    ["Is an MOT check report a conclusive indicator of quality and condition?", "The Auto Reg Check report provides vehicle data from official sources, enabling you to make an informed decision about a vehicle’s quality. We do not advise on whether you should buy a vehicle; that decision is yours."],
    ["Does an MOT check report include all incidents and major events?", "We use data from official sources. If an accident is recorded in the official database, it will appear in your MOT check report. This applies to all official databases we use. However, some incidents may not be recorded, such as unnotified repairs after a write-off, and thus won't appear in the report. For example, if a car is written off and repaired without the insurance company being notified, then there will be no record in the official database and it will therefore not show up in an Auto Reg Check report."],
    ["How do I find my tax and MOT due dates?", "Enter your registration number at the top of this page, wait for the free MOT check report to load, then go to the MOT history section to check the MOT and tax expiry dates."],
    ["How do I check a vehicle’s MOT history?", "Our free car check includes a basic MOT check. Enter your number plate to see the latest MOT result. For full MOT history, purchase a full vehicle check for £4.99."],
]

const QUESTIONS_TAX = [
    ["Is purchasing a full vehicle check worthwhile?", "With 25% of vehicles having concealed histories, it's important not to gamble when buying a used car. A comprehensive vehicle check using just a registration number offers instant reassurance that a vehicle is secure and in good condition. Plus, at just £4.99, it's a bargain."],
    ["Is a free tax check available?", "Absolutely! Our free tax check offers information on the MOT and tax status of the vehicle, along with the tax expiry date."],
    ["How does this service distinguish itself from GOV.UK?", "Auto Reg Check compiles more extensive data than the GOV.UK service, presenting it in a user-friendly tax check report for your perusal at your convenience. Our objective is to provide comprehensive information to aid you in making an informed purchase."],
    ["How is data assembled in your tax check reports?", "Auto Reg Check operates as an automated vehicle inspection service. Upon conducting a number plate inquiry, our system interrogates databases, retrieving over 800 data points, which we subsequently arrange into a structured, navigable report."],
    ["How can I access my tax check report?", "Your Auto Reg Check report is accessible as a web application, instantly available after purchase. It requires an internet connection for viewing at any time. The web link will be dispatched via email. We are also in the process of offering PDF format."],
    ["Is a tax check report a definitive gauge of quality and condition?", "The Auto Reg Check report collects vehicle data from authoritative sources, empowering you to make an informed judgment regarding a vehicle’s quality. We refrain from advising on whether you should purchase a vehicle; that determination rests with you."],
    ["Does a tax check report encompass all occurrences and significant events?", "We utilise data from authoritative sources. Any accidents recorded in the official database will be reflected in your tax check report. This holds true for all official databases we leverage. Nonetheless, certain incidents may avoid official recording, like unreported repairs following a write-off, and consequently won't feature in the report. For instance, if a car undergoes a write-off and subsequent repairs without notifying the insurance company, it won't be documented in the official database and thus won't be included in an Auto Reg Check report."],
    ["How can I ascertain my tax and MOT due date?", "Input your registration number in the number plate at the top of the page, the free tax check report will load then navigate to the MOT history segment to verify the MOT and tax expiration dates."],
    ["How can I examine a vehicle’s tax details? ", "Our free check features a basic tax check. Enter your number plate to view the tax details for the vehicle as well as the most recent MOT result."],
]

const QUESTIONS_REG = [
    ["Unsure about the need for a full reg check?", "Statistics show that 25% of vehicles have undisclosed issues. Avoid risks when buying a used car. Conducting a comprehensive reg check ensures safety and quality assurance. Gain peace of mind instantly for only £4.99."],
    ["Looking for a free reg check?", "You're in the right place! Our no-cost reg check collates fundamental details about your car, including an MOT and tax check, export status, recall alerts, and the latest MOT records."],
    ["What sets apart Auto Reg Check from GOV.UK?", "Auto Reg Check procures a broader spectrum of data than the governmental service, delivering it in a user-centric reg check report for your convenience. Our goal is to provide maximum information, empowering your decision making."],
    ["Wondering about our reg check report generation process?", "Auto Reg Check operates as an automated vehicle inspection service. Initiating a reg check triggers an extensive database query yielding over 800 data points. We then organise this information into a structured report for you to seamlessly browse."],
    ["How do I access a reg check report?", "Upon completing the reg check purchase, your Auto Reg Check report becomes instantly available as a web application. An internet connection is all you need for accessing it at any time. Additionally, the web link is dispatched to your email upon purchase. Stay tuned; PDF format reports are in the pipeline."],
    ["Is a reg check report conclusive about quality and condition?", "Auto Reg Check compiles vehicle data from official channels, facilitating informed decisions on quality. However, we refrain from suggesting if a vehicle should be purchased; that decision rests solely with you."],
    ["Does a reg check report include all incidents and significant occurrences?", "Our data originates from official channels. Any accidents recorded officially will be included in your reg check report. However, unreported incidents may occur due to unethical practices in vehicle repair and resale. For example, if a vehicle is salvaged and restored without informing the insurer, it won't appear in our report."],
    ["Seeking tax and MOT due dates?", "Simply input your reg number above, wait for the free reg check report to load, and proceed to the MOT history section. There, you'll discover the vehicle's tax and MOT expiry dates."],
    ["Curious about a car's MOT history?", "Our free reg check offers a basic MOT examination. Input your number plate for swift access to the latest MOT result. For a comprehensive vehicle history, opt for our £4.99 full reg check."],
]

const QUESTIONS_VEHICLE = [
    ["Do I need a full vehicle check?", "1 in 4 vehicles have a hidden history. It’s wise not to take chances when purchasing a used car. Performing a full vehicle check using your registration number provides immediate assurance that a vehicle is in safe and good condition. For just £4.99, you can get instant peace of mind."],
    ["Do you offer a free vehicle check?", "Yes, we do! Our free vehicle check includes basic information about the car. This includes an MOT and tax check, an exported check, and any recall notices. Additionally, you will get the vehicle's most recent MOT history."],
    ["How is this vehicle check service different from the GOV.UK service?", "Auto Reg Check gathers more extensive data than the GOV.UK service, presenting it in a user-friendly vehicle check report that you can review at your convenience. We aim to provide as much information as possible, helping you make an informed decision about buying a used car."],
    ["How is data compiled into your vehicle check reports?", "Auto Reg Check is an automated vehicle check service. Running a number plate check with our system triggers a database query that can return over 800 data points about a vehicle. We compile these data points into a structured report for you to explore."],
    ["In which formats can I receive my vehicle check report?", "Your Auto Reg Check report is accessible as a web application immediately after purchase and completion of the vehicle check. You will need an internet connection to view the report anytime. The web link will also be emailed to you upon purchase. We are working on offering our reports in PDF format."],
    ["Can a vehicle check report be a definitive indicator of a vehicle's quality and condition?", "The Auto Reg Check report presents vehicle data from official sources, allowing you to make an informed decision about a vehicle’s quality. We do not offer recommendations on whether to buy a vehicle; that decision is entirely yours."],
    ["Does a vehicle check report cover all incidents and major events?", "We use data from official sources. If an accident is recorded in the official database, it will appear in your vehicle check report. However, improper conduct in vehicle repair and resale can lead to unreported events. For instance, if a car is written off and repaired without notifying the insurance company, it won’t be in the official database and thus won’t appear in an Auto Reg Check report."],
    ["How can I find when my tax and MOT is due?", "Enter your reg number at the top of this page, wait for the free vehicle check report to load, and then navigate to the MOT history section. There, you can find the MOT and tax expiry dates for the vehicle."],
    ["How can I check a vehicle's MOT history?", "Our free vehicle check includes a basic MOT check for a vehicle. Entering your number plate lets you quickly view the latest MOT test result. To access the complete vehicle history, you need to buy a full vehicle check for £4.99."],
]

const QUESTIONS_NUMBER = [
    ["Do I need a full number plate check?", "1 in 4 vehicles have a concealed history. It's not advisable to risk going without a number plate check when buying a used car. Conducting a full number plate check offers immediate assurance that a vehicle is safe and in good condition. For only £4.99, you can secure peace of mind instantly."],
    ["Do you offer a free number plate check?", "Yes, we do! Our free number plate check provides basic car information. This includes an MOT and tax check, export check, and any recall notices. Additionally, you'll receive the vehicle's most recent MOT history."],
    ["How is this number plate check service different from the GOV.UK service?", "Auto Reg Check collects more comprehensive data than the GOV.UK service, presenting it in an easy-to-understand number plate check report that you can review at your convenience. We strive to provide extensive information, helping you make an informed decision when buying a used car."],
    ["How is data compiled into your number plate check reports?", "Auto Reg Check is an automated number plate check service. Running a number plate check with our system triggers a database query that can return over 800 data points about a vehicle. We compile these data points into a structured report for you to explore."],
    ["In which formats can I receive my number plate check report?", "Your Auto Reg Check report is available as a web application immediately after purchasing and completing the number plate check. You will need an internet connection when you wish to view the report. The web link will also be emailed to you upon purchase. We are working on offering our reports in PDF format."],
    ["Can a number plate check report be a definitive indicator of a vehicle's quality and condition?", "The Auto Reg Check report presents vehicle data from official sources, allowing you to make an informed decision about a vehicle’s quality. We do not offer recommendations on whether to buy a vehicle; that decision is entirely up to you."],
    ["Does a number plate check report cover all accidents and significant incidents?", "We only use data from official sources. If an accident is recorded in the official database, it will appear in your number plate check report. However, if a car is written off and repaired without the insurance company being notified, it won’t appear in the official database and thus won’t be in an Auto Reg Check report."],
    ["How can I find my tax and MOT due date?", "At the top of this page, enter your reg number and wait for the free number plate check report to load, then navigate to the MOT history part of the report. There, you can find the MOT and tax expiry dates for the vehicle."],
    ["How can I check a car's MOT history?", "Our free number plate check includes a free MOT check for a car. Enter your number plate to quickly view the latest MOT test result. To get the complete vehicle history for a vehicle, you will need to buy a full number plate check for £4.99."],
]


class Homepage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            active_preview: 0,
            active_review: 0,
            h1: "Get a top-rated car check for any vehicle",
            title: "Best FREE UK Car Check | Auto Reg Check",
            desc: "Reveal any car's MOT history with our FREE car check. Want to avoid £1,000s in costly repairs? Buy a full check for only £4.99. Don't leave it to chance.",
            features: [
                ['Vehicle Overview', "Your vehicle check begins with a high-level snapshot of the vehicle that highlights the results of the most critical car checks we have carried out into the car. Key milestones in the car's journey are presented, like registrations and previous keepers, along with the car's Euro NCAP score and VIN.", 'overview'],
                ['12-point Vehicle Check', "View the results of our comprehensive 12-point history check in an easily digestible dashboard. Covering crucial areas such as outstanding finance, stolen status, and write-offs, each failed check can be expanded to view more information.", 'history'],
                ['Valuation', "Discover the vehicle's current estimated value, along with 5 valuations from real-world scenarios and the estimated depreciation curve from the car's life span. Our cost calculator provides an estimate of the annual expenses the car will incur, including fuel and tax cost.", 'valuation'],
                ['Tax & MOT Check', "Examine the vehicle's MOT history, historical pass rates and full results in a user-friendly table. Current MOT and tax status are presented, complete with prevalent issues specific to the make/model of vehicle and broad MOT insights for comparative analysis.", 'mot'],
                ['Eco-Impact', "Our Eco Impact section assigns a unique eco score to the vehicle, making it easy to compare the environmental impact between cars. Emissions and fuel economy (MPG) data is presented, alongside clean air zone checks, noise pollution and the green NCAP factsheet for the vehicle.", 'eco'],
                ['Factory Specs', "Read a break down of all the data we hold for the vehicle with regards to performance, dimensions, weight and registration details. Discover the factory equipment list and optional extras, all collated in a format designed for ease of understanding and accessibility.", 'spec'],
            ],
            why: [
                "When it comes to buying a used car, a comprehensive car check is crucial for making an informed decision. With a multitude of vehicle check services available, it can be challenging to find a car check service that truly serves your needs.",
                "That's where Auto Reg Check comes in – a new car check service that offers a unique blend of innovative data-engineering, immersive design, and unprecedented levels of vehicle information."
            ],
            faq_intro: 'Auto Reg Check collates data about a vehicle from numerous sources and compiles it into a user-friendly, easily digestible car check report. If there is data available for your car, the report will highlight any areas of concern with regards to the vehicle’s history, condition or value. When you enter your number plate into our site, we run a query of our databases that can return over 800 data points about a vehicle. Our data about a car comes from sources such as the DVLA, SMMT, VOSA and many more.',
            applications: [
                ["Check that a car’s history is genuine", "Gain peace of mind by verifying the legitimacy of any car's past, before you move ahead with buying a used vehicle."],
                ["Discover a vehicle’s true condition", "Use our car check to reveal the true condition of any vehicle. Take our report to viewings and compare notes for a thorough vehicle inspection."],
                ["Gain a powerful bargaining chip", "Equip yourself with detailed vehicle insights from our comprehensive car check to negotiate with confidence and secure the best deal possible."]
            ],
            questions: QUESTIONS_DEFAULT,
            questions_extras: [
                "What's included in a full car check?",
                "Our full car check included a 12-point history check for your car:",
                "Also included with this package are several sections that break down the vehicle's lifetime, current valuation, MOT history, tax check, eco-impact plus complete factory specs and optional extras, all wrapped up into a user-friendly web application that can be browsed at any time."
            ],
            url: "https://www.autoregcheck.co.uk/" 
        }
    }

    componentDidMount() {
        document.getElementById('btn_review_back').addEventListener('click', () => { this.handle_review_change('left') })
        document.getElementById('btn_review_forward').addEventListener('click', () => { this.handle_review_change('right') })

        let URL = window.location.href.split('/')
        let variation = URL[URL.length - 1]

        if(window.location.href[window.location.href.length - 1] === '/'){
            variation = URL[URL.length - 2]
        }

        if (variation) {
            switch (variation) {
                case 'mot-check':
                    this.setState({
                        url: "https://www.autoregcheck.co.uk/mot-check/",
                        title: "Top-rated MOT Check | Check The MOT History Of Any Car",
                        desc: "Discover your vehicle's MOT history and status with a free MOT check from Auto Reg Check. 29% of cars on UK roads fail their MOT. Don't leave it to chance.",
                        h1: "Get a free MOT check for any vehicle",
                        features: [
                            ['Vehicle Overview', "The vehicle check starts with a comprehensive overview of the car, showcasing key findings from our essential car checks. Important milestones such as registrations and previous ownerships are displayed, along with the euro safety rating and VIN.", 'overview'],
                            ['12-point Vehicle Check', "See the results of our detailed 12-point history check in a user-friendly dashboard. It covers vital aspects such as outstanding finance, stolen status, and write-offs, with each check shown in an expandable list for more details.", 'history'],
                            ['Valuation', "Find out the vehicle’s current estimated value, along with five real-world valuations and the projected depreciation curve. Our cost calculator estimates annual expenses, including fuel and tax costs.", 'valuation'],
                            ['Tax & MOT Check', "Review the vehicle's MOT history, past pass rates, and complete results in an accessible table. Current MOT and tax status are included, along with common issues for the make/model and general MOT insights for comparison.", 'mot'],
                            ['Eco-Impact', "The Eco Impact section gives the vehicle a unique eco score, simplifying environmental impact comparisons. Emissions and MPG data are shown, along with a ULEZ check, noise pollution, and the green NCAP factsheet.", 'eco'],
                            ['Factory Specs', "Access a detailed breakdown of all data we have for the vehicle, including performance, dimensions, weight, and registration details. Explore the factory equipment list and optional extras, all organized for easy understanding and accessibility.", 'spec'],
                        ],
                        why: [
                            "Purchasing a used car necessitates a thorough car check for informed decision-making. With many MOT check services on the market, it’s tough to find one that meets all your needs.",
                            "Auto Reg Check stands out – a fresh car check service combining innovative data-engineering, engaging design, and unparalleled vehicle information."
                        ],
                        faq_intro: "Auto Reg Check gathers vehicle data from multiple sources and assembles it into an accessible, easy-to-understand report. If data is available, the report will flag any concerns about the vehicle’s history, condition, or value. By entering your number plate on our site, we query databases that can return over 800 data points. Our data sources include the DVLA, SMMT, VOSA, and more.",
                        applications: [
                            ["Check the authenticity of any car’s history", "Ensure peace of mind by checking any car's history before purchasing a used vehicle."],
                            ["Uncover a vehicle’s true condition", "Use our MOT check to reveal the MOT history of any vehicle. Bring our report to viewings and compare findings for a complete inspection."],
                            ["Acquire a strong bargaining tool", "Arm yourself with detailed insights from our thorough car check to negotiate effectively and get the best deal possible."]
                        ],
                        questions: QUESTIONS_MOT,
                        questions_extras: [
                            "What's in a full car check?",
                            "Our full car check includes a 12-point history check for your car:",
                            "Additionally, this package offers sections on the vehicle’s lifetime, current value, a full MOT check with complete MOT history, tax check, eco-impact, and complete factory specs and optional extras, all within a user-friendly web application available anytime."
                        ]

                    })
                    break
                case 'tax-check':
                    this.setState({
                        url: "https://www.autoregcheck.co.uk/tax-check/",
                        title: "Top-rated Tax Check | Check The Tax Status Of Any Car",
                        desc: "Uncover your car's tax status and condition with a free tax check from Auto Reg Check. 500,000 cars are untaxed on UK roads. Don’t leave it to chance.",
                        h1: "Get a free tax check for any vehicle",
                        features: [
                            ['Vehicle Overview', "The tax check starts with a thorough overview of the car, presenting key findings from our crucial car checks. Significant details such as registrations and previous owners are shown, along with the NCAP rating and VIN.", 'overview'],
                            ['12-point Vehicle Check', "View the results of our extensive 12-point history check in an intuitive dashboard. It includes critical factors like outstanding finance, stolen status, and write-offs, with each vehicle check displayed on a clickable card for more details.", 'history'],
                            ['Valuation', "Discover the vehicle’s current estimated value, along with five real-world valuations and the projected depreciation curve. Our cost calculator estimates yearly expenses, including fuel and tax costs.", 'valuation'],
                            ['Tax & MOT Check', "Examine the vehicle's MOT history, previous pass rates, and complete results in an easy-to-read table. Current MOT and tax status are included, along with common issues for the make/model and general MOT insights for comparison.", 'mot'],
                            ['Eco-Impact', "The Eco Impact section assigns the vehicle a unique eco score, making environmental impact comparisons straightforward. Emissions and fuel economy data are provided, along with a ULEZ check, noise pollution, and the green NCAP score.", 'eco'],
                            ['Factory Specs', "Access a detailed breakdown of all data available for the vehicle, including engine, performance, weight, and registration details. Explore the factory equipment list and optional extras, all organized for easy comprehension and accessibility.", 'spec'],
                        ],
                        why: [
                            "Buying a used car requires a thorough tax check for informed decision-making. With numerous vehicle check services available, it’s challenging to find one that fulfills all your needs.",
                            "Auto Reg Check excels – a new tax check service blending innovative data-engineering, engaging design, and unmatched vehicle information."
                        ],
                        faq_intro: "Auto Reg Check collects vehicle data from various sources and compiles it into an easy-to-understand report. If available, the report will highlight any issues about the vehicle’s history, condition, or value. By entering your number plate on our site, we access databases that can provide over 800 data points. Our data sources include the DVLA, SMMT, VOSA, and more.",
                        applications: [
                            ["Validate any vehicle's history", "Guarantee peace of mind by validating the authenticity of a vehicle's history before investing in a used car."],
                            ["Reveal a vehicle's genuine condition", "Utilise our car check to unveil the true history of any vehicle. Present our report during viewings and investigate the vehicle using the tax check report's findings."],
                            ["Gain a powerful bargaining chip", "Arm yourself with unique insights from our tax check report to negotiate effectively and get the best deal possible."]
                        ],
                        questions: QUESTIONS_TAX,
                        questions_extras: [
                            "What's included in a full car check?",
                            "Our full car check includes a 12-point history check for your car:",
                            "Additionally, this package offers sections on the vehicle’s lifetime, current value, a full tax check with complete tax history, tax status, eco-impact, and complete factory specs and optional extras, all within a user-friendly web application available anytime."
                        ]
                    })
                    break
                case 'vehicle-check':
                    this.setState({
                        url: "https://www.autoregcheck.co.uk/vehicle-check/",
                        title: "Top-rated Vehicle Check | Check The History Of Any Car",
                        desc: "Ensure your car is safe and in top condition with a full vehicle check from Auto Reg Check. 1 in 4 vehicles have a dodgy history. Don’t leave it to chance.",
                        h1: "Get a top-rated vehicle check for any car",
                        features: [
                            ['Vehicle Overview', "Your vehicle check starts with a detailed overview, showcasing the results of the essential vehicle checks we perform. Major milestones in the car's history, such as registrations and prior owners, are displayed, along with the car's Euro NCAP rating and VIN.", 'overview'],
                            ['12-point Vehicle Check', "Access the results of our thorough 12-point history check in a simple dashboard. Important aspects like outstanding finance, theft status, and write-offs are shown on clickable cards for detailed information.", 'history'],
                            ['Valuation', "Find the vehicle's current market value, along with five real-world valuations and the projected depreciation curve over its lifespan. Our cost calculator estimates annual expenses for the car, including fuel and tax costs.", 'valuation'],
                            ['Tax & MOT Check', "Review the vehicle's MOT history, past pass rates, and full results in an easy-to-read table. Current MOT and tax status are included, along with common issues specific to the vehicle’s make/model and general MOT insights for comparison.", 'mot'],
                            ['Eco-Impact', "The Eco Impact section gives a unique eco score for the vehicle, enabling easy comparison of environmental impact between cars. Emissions and fuel economy (MPG) data are shown, along with a ULEZ check, noise pollution, and the green NCAP factsheet.", 'eco'],
                            ['Factory Specs', "Explore a breakdown of all the data we have for the vehicle, including performance, dimensions, engine, and registration details. See the factory equipment list and optional extras, all organized for easy understanding and accessibility.", 'spec'],
                        ],
                        why: [
                            "When purchasing a used car, a thorough vehicle check is essential for an informed choice. With many vehicle check services out there, finding one that meets your needs can be tough.",
                            "Auto Reg Check stands out from the crowd – a new vehicle check service providing a unique mix of advanced data-engineering, engaging design, and unparalleled vehicle information."
                        ],
                        faq_intro: "Auto Reg Check gathers data from multiple sources and presents it in a user-friendly, easily digestible vehicle check report. If information is available, the report will flag any concerns regarding the vehicle’s history, condition, or value. Entering your number plate on our site triggers a database query that can return over 800 data points about the vehicle. Our data comes from sources like the DVLA, SMMT, VOSA, and more.",
                        applications: [
                            ["Verify that a vehicle’s history is genuine", "Gain peace of mind by confirming the authenticity of any vehicle's past before proceeding with the purchase of a used car."],
                            ["Reveal a vehicle’s true condition", "Use our vehicle check to uncover the actual condition of any car. Bring our report to viewings and compare notes for a comprehensive vehicle inspection."],
                            ["Get a strong bargaining tool", "Prepare yourself with insights from our comprehensive vehicle check to negotiate confidently and secure the best possible deal."]
                        ],
                        questions: QUESTIONS_VEHICLE,
                        questions_extras: [
                            "What's included in a full vehicle check?",
                            "Our full car check includes a 12-point history check for your car:",
                            "This package also covers the vehicle's life span, current valuation, MOT history, tax check, eco-impact, and full factory specs and optional extras, all organised in a user-friendly web app that can be accessed anytime."
                        ]
                    })
                    break
                case 'reg-check':
                    this.setState({
                        url: "https://www.autoregcheck.co.uk/reg-check/",
                        title: "Top-rated Reg Check | Check The Full History Of Any Car",
                        desc: "Make sure a car is safe and in the best condition with a full reg check from Auto Reg Check. 1 in 4 cars are hiding something. Don’t leave it to chance.",
                        h1: "Get a top-rated reg check for any vehicle",
                        features: [
                            ['Vehicle Overview', "A reg check starts with a detailed overview of the car, highlighting the results of the essential car checks we perform. Major milestones in the car's history, such as registrations and prior owners, are displayed, along with the car's Euro safety rating and VIN.", 'overview'],
                            ['12-point Vehicle Check', "Explore the comprehensive results of our 12-point vehicle history check via an intuitive dashboard. Key elements such as outstanding finance, theft status, and write-offs are conveniently displayed on interactive cards for detailed insights.", 'history'],
                            ['Valuation', "Discover the current market valuation of the vehicle, accompanied by five authentic valuations and a forecasted depreciation trajectory throughout its life. Our expenditure calculator approximates yearly costs, encompassing fuel expenses and service costs.", 'valuation'],
                            ['Tax & MOT Check', "Examine the vehicle's MOT history, previous pass rates, and comprehensive findings presented in a user-friendly tabular format. Current MOT and tax status are provided, alongside model-specific common issues and broader MOT insights.", 'mot'],
                            ['Eco-Impact', "Delve into the Eco Impact segment, offering an exclusive eco score for the vehicle, facilitating seamless environmental impact contrasts among vehicles. Emissions and fuel economy (MPG) figures are displayed, accompanied by a ULEZ check, noise pollution insights, and the green NCAP factsheet.", 'eco'],
                            ['Factory Specs', "Navigate through a detailed breakdown of the vehicle's data, encompassing performance metrics, dimensions, weight specifications, and registration info. Browse the factory equipment inventory and optional add-ons, meticulously arranged for straightforward comprehension and accessibility.", 'spec'],
                        ],
                        why: [
                            "When considering buying a used car, conducting a comprehensive reg check is crucial for making an informed decision. Amidst numerous reg check providers, identifying one aligned with your requirements can be challenging.",
                            "Auto Reg Check distinguishes itself from the competition – a vehicle check service offering a distinctive blend of cutting-edge data engineering, captivating design, and incomparable vehicle insights."
                        ],
                        faq_intro: "Auto Reg Check aggregates data from across the internet and compiles it into a user-friendly, comprehensible reg check report. Where data is available, the report highlights any concerns with the vehicle's history, condition, or value. Inputting your number plate on our platform initiates a database search of over 800 data points relating to the vehicle. Our data derives from reputable sources including the DVLA, SMMT, VOSA, and others.",
                        applications: [
                            ["Check any vehicle's background", "Ensure peace of mind by verifying its authenticity before finalising a used car purchase."],
                            ["Unveil a vehicle's true condition", "Employ our reg check to unveil the true condition of any car. Take our report to inspections to make sure you carry out a thorough examination."],
                            ["Equip yourself with a strong bargaining tool", "Utilise insights from our thorough reg check to negotiate with assurance and clinch the optimal deal."]
                        ],
                        questions: QUESTIONS_REG,
                        questions_extras: [
                            "What does a full reg check provide?",
                            "Our full reg check includes a 12-point history check for your car:",
                            "A full reg check also covers the vehicle's life span, current valuation, MOT history, tax check, eco-impact, and full factory specs and optional extras, all organised in a user-friendly web app that can be accessed anytime."
                        ]
                    })
                    break
                case 'number-plate-check':
                    this.setState({
                        url: "https://www.autoregcheck.co.uk/number-plate-check/",
                        title: "Top-rated Number Plate Check | Full History For Any Car",
                        desc: "Secure your vehicle's safety and integrity with a thorough number plate check from Auto Reg Check. Many cars have hidden issues. Don't leave it to chance.",
                        h1: "Get a top-rated number plate check for any car",
                        features: [
                            ['Vehicle Overview', "Begin your number plate check with an in-depth summary, highlighting key car checks we conduct. Key moments in the car's history, including past registrations and previous owners, are presented, alongside the car's VIN and safety rating.", 'overview'],
                            ['12-point Vehicle Check', "View the results of our comprehensive 12-point history check in an easy-to-use dashboard. Critical details such as outstanding finance, theft records, and write-offs are displayed on interactive cards for more information.", 'history'],
                            ['Valuation', "Discover the vehicle's present market value, alongside five real-world appraisals and a forecasted depreciation curve. Our cost calculator predicts annual costs for the car, covering expenses such as fuel and insurance.", 'valuation'],
                            ['Tax & MOT Check', "Examine the vehicle's MOT history, previous pass rates, and full results in a clear table. Current MOT and tax status are provided, along with typical issues for the car’s make/model and general MOT insights for comparison.", 'mot'],
                            ['Eco-Impact', "The Eco Impact section provides a bespoke eco score, allowing straightforward comparison of cars' environmental impacts. Emissions and MPG data are displayed, along with a ULEZ check, noise pollution, and the green NCAP factsheet.", 'eco'],
                            ['Factory Specs', "Explore a detailed breakdown of all vehicle data, including performance, dimensions, rims, weight, and engine info. View the factory equipment list and optional extras, all arranged for clear understanding and easy access.", 'spec'],
                        ],
                        why: [
                            "When buying a used car, a detailed number plate check is crucial for making an informed decision. With numerous car check services available, finding the right one can be challenging.",
                            "Auto Reg Check distinguishes itself with a blend of advanced data-engineering, captivating design, and unmatched vehicle details."
                        ],
                        faq_intro: "Auto Reg Check collects data from various sources, presenting it in a user-friendly, easily digestible number plate check report. If available, the report flags any concerns about the vehicle’s history, condition, or value. Entering your number plate on our site initiates a database search, returning over 800 data points about the vehicle. Our data sources include the DVLA, SMMT, VOSA, and more.",
                        applications: [
                            ["Check that a car’s history is genuine", "Gain peace of mind by checking the legitimacy of any car's past, before you move ahead with buying a used car."],
                            ["Find out any vehicle’s true condition", "Use our number plate check to reveal the true condition of any car. Take our report to viewings and compare notes for a thorough vehicle inspection."],
                            ["Gain a powerful bargaining chip", "Equip yourself with detailed vehicle insights from our number plate check service to negotiate with confidence and secure the best deal possible."]
                        ],
                        questions: QUESTIONS_NUMBER,
                        questions_extras: [
                            "What's included in a full number plate check?",
                            "Our full number plate check includes a 12-point history check for your car:",
                            "A full number plate check also covers the vehicle's life span, current valuation, MOT history, tax check, eco-impact, and full factory specs and optional extras, all organised in a user-friendly web app that can be accessed anytime."
                        ]
                    })
                    break
                default:
            }
        }

        if (localStorage.getItem('cookies_accepted') === "accepted") {

            (function (h, o, t, j, a, r) {
                h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
                h._hjSettings = { hjid: 3716479, hjsv: 6 };
                a = o.getElementsByTagName('head')[0];
                r = o.createElement('script'); r.async = 1;
                r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
                a.appendChild(r);
            })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
        }
    }

    handle_preview_change = (direction) => {
        if (direction === "right") {
            this.setState({
                active_preview: this.state.active_preview === this.state.features.length - 1 ? 0 : this.state.active_preview + 1
            })
        } else {
            this.setState({
                active_preview: this.state.active_preview === 0 ? this.state.features.length - 1 : this.state.active_preview - 1
            })
        }
    }

    handle_review_change = (direction) => {
        if (direction === "right") {
            this.setState({
                active_review: this.state.active_review === REVIEWS['testimonials'].length - 1 ? 0 : this.state.active_review + 1
            })
        } else {
            this.setState({
                active_review: this.state.active_review === 0 ? REVIEWS['testimonials'].length - 1 : this.state.active_review - 1
            })
        }
    }


    render() {
        console.log(this.state.questions)
        return (
            <HelmetProvider>
                <Helmet>
                    <link rel="canonical" href={this.state.url} />
                    <title>{this.state.title}</title>
                    <meta name="description" content={this.state.desc} />
                </Helmet>

                <main id="main_homepage">

                    <Header active_nav={'home'} show_banner={true} />

                    <section id="opener">
                        <div className="section_cont" >

                            <div id="opener_content">
                                <h1>{this.state.h1}</h1>
                                <h2>{this.state.desc} <br /><br /><b>ARC reports are currently unavailable, we apologise for any inconvenience.</b></h2>

                                {/* <PlateInput input_id='opener_plate_input' /> */}

                                <div id="opener_list">
                                    <div className="opener_list_item">
                                        <p>History Check</p>
                                        <img src='./assets/icons/small_check.svg' alt='Check mark' draggable='false' />
                                    </div>
                                    <div className="opener_list_item">
                                        <p>{window.innerWidth >= 600 && window.innerWidth <= 904 ? "Tax Check" : "Valuation"}</p>
                                        <img src='./assets/icons/small_check.svg' alt='Check mark' draggable='false' />
                                    </div>
                                    <div className="opener_list_item">
                                        <p>{window.innerWidth >= 600 && window.innerWidth <= 904 ? "Valuation" : "MOT Check"}</p>
                                        <img src='./assets/icons/small_check.svg' alt='Check mark' draggable='false' />
                                    </div>
                                    <div className="opener_list_item">
                                        <p>{window.innerWidth >= 600 && window.innerWidth <= 904 ? "Eco Score" : "Tax Check"}</p>
                                        <img src='./assets/icons/small_check.svg' alt='Check mark' draggable='false' />
                                    </div>
                                    <div className="opener_list_item">
                                        <p>{window.innerWidth >= 600 && window.innerWidth <= 904 ? "MOT Check" : "Eco Score"}</p>
                                        <img src='./assets/icons/small_check.svg' alt='Check mark' draggable='false' />
                                    </div>
                                    <div className="opener_list_item">
                                        <p>ULEZ Check</p>
                                        <img src='./assets/icons/small_check.svg' alt='Check mark' draggable='false' />
                                    </div>
                                </div>

                            </div>

                            <div id="opener_image">
                                <img fetchpriority="high" src='./assets/homepage/AB_4.webp' alt='' draggable='false' />
                                {/* <img fetchpriority="high" src={window.innerWidth >= 600 && window.innerWidth <= 904 ? './assets/homepage/iphone_replacement.webp' : './assets/homepage/iphone.svg'} alt='' draggable='false' /> */}
                            </div>

                        </div>
                    </section>


                    <section id="features">
                        <div className="section_cont">

                            <div id='reports'>
                                <div id='text'>
                                    <h2>Features</h2>
                                    <div id='preview_header'>
                                        <h3>{this.state.features[this.state.active_preview][0]}</h3>
                                        <div id='nav'>
                                            <img onClick={() => { this.handle_preview_change('left') }} draggable='false' alt='A left arrow' src='./assets/icons/arrow_left.svg' />
                                            <img onClick={() => { this.handle_preview_change('right') }} draggable='false' alt='A right arrow' src='./assets/icons/arrow_right.svg' />
                                        </div>
                                    </div>
                                    <p>{this.state.features[this.state.active_preview][1]}</p>

                                </div>

                                <div id='preview'>
                                    <img className="preivew_img" alt="A report preview" src={`./assets/homepage/preview_${this.state.features[this.state.active_preview][2]}.webp`} />
                                </div>
                            </div>

                            <div id='steps'>

                                <div className="step_item">
                                    <div className="marker"><p>1</p></div>
                                    <div className="step_text">
                                        <h3 className="step_title">Enter your reg to begin</h3>
                                        {(window.innerWidth < 600 || window.innerWidth > 904) && <p className="step_subtitle">Start your free car check today</p>}
                                    </div>
                                </div>

                                <div className="step_item">
                                    <div className="marker"><p>2</p></div>
                                    <div className="step_text">
                                        <h3 className="step_title">Recieve your free car check</h3>
                                        {(window.innerWidth < 600 || window.innerWidth > 904) && <p className="step_subtitle">Instantly view key vehicle checks</p>}
                                    </div>
                                </div>

                                <div className="step_item">
                                    <div className="marker"><p>3</p></div>
                                    <div className="step_text">
                                        <h3 className="step_title">Upgrade to a full car check</h3>
                                        {(window.innerWidth < 600 || window.innerWidth > 904) && <p className="step_subtitle">Get a complete vehicle history</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="why_choose_us">
                        <div className='section_cont'>
                            <h2>Why choose us?</h2>
                            <div id='why_content'>
                                <p id='why_text'>{this.state.why[0]}<br /><br />{this.state.why[1]}</p>
                                <div id='list'>
                                    <div className="list_item">
                                        <div className="marker"><p>1</p></div>
                                        <p>The UK’s best value car check at £4.99</p>
                                        <img src='./assets/icons/small_check.svg' draggable='false' alt='A checkbox' />
                                    </div>

                                    <div className="list_item">
                                        <div className="marker"><p>2</p></div>
                                        <p>Try our free car check before you buy</p>
                                        <img src='./assets/icons/small_check.svg' draggable='false' alt='A checkbox' />
                                    </div>

                                    <div className="list_item">
                                        <div className="marker"><p>3</p></div>
                                        <p>Receive a user-friendly vehicle check report</p>
                                        <img src='./assets/icons/small_check.svg' draggable='false' alt='A checkbox' />
                                    </div>

                                    <div className="list_item">
                                        <div className="marker"><p>4</p></div>
                                        <p>Car check data from the DVLA and beyond</p>
                                        <img src='./assets/icons/small_check.svg' draggable='false' alt='A checkbox' />
                                    </div>

                                    <div className="list_item">
                                        <div className="marker"><p>5</p></div>
                                        <p>Check ULEZ and get environmental impacts</p>
                                        <img src='./assets/icons/small_check.svg' draggable='false' alt='A checkbox' />
                                    </div>
                                </div>

                            </div>
                        </div>

                    </section>

                    <section id="faqs">
                        <div className="section_cont">

                            <div id='faq_intro'>
                                <div id='faq_text'>
                                    <h2>FAQs</h2>
                                    <p>{this.state.faq_intro}</p>
                                </div>

                                <img src='./assets/homepage/faq.webp' alt='A phone with an ARC report on the screen' />

                            </div>

                            <div id='questions'>
                                <>
                                    <FAQ question={this.state.questions_extras[0]} dropdown={false}>
                                        <p>{this.state.questions_extras[1]}</p>
                                        <ul>
                                            <li>Outstanding finance check</li>
                                            <li>Stolen check</li>
                                            <li>Scrap check</li>
                                            <li>VIC</li>
                                            <li>Imported check</li>
                                            <li>Exported check</li>
                                            <li>Number plate changes</li>
                                            <li>Mileage check</li>
                                            <li>Write-off check</li>
                                            <li>High risk records</li>
                                            <li>Colour changes</li>
                                            <li>Recalls</li>
                                        </ul>
                                        <p>{this.state.questions_extras[2]}</p>
                                    </FAQ>

                                    {this.state.questions.map((item, i) => (
                                        <div key={i}>
                                            <FAQ question={item[0]} dropdown={false}>
                                                <p>{item[1]}</p>
                                            </FAQ>
                                        </div>
                                    ))}
                                    <FAQ question={"Where can I pay my road tax?"} dropdown={false}>
                                        <p>Road tax can be paid online using the GOV.UK <a className='normal_link' href='https://www.gov.uk/vehicle-tax' target="_blank">online portal</a>.</p>
                                    </FAQ>

                                </>

                            </div>
                        </div>
                    </section>

                    <section id="reviews">
                        <div className="section_cont">
                            <h2>Reviews</h2>
                            <q id='review_text'>{REVIEWS['testimonials'][this.state.active_review][0]}</q>

                            <div id='review_footer'>

                                {/* <div id='info'>
                                    <img draggable='false' src={`./assets/homepage/avatars/${this.state.active_review}.png`} alt='' />
                                    <div id='review_info'>
                                        <p>{`— ${REVIEWS['testimonials'][this.state.active_review][1]}`}</p>
                                        <img src='./assets/review_5_stars.svg' draggable='false' alt='5 stars' />
                                    </div>
                                </div> */}

                                <div id='review_nav'>
                                    <md-outlined-button id='btn_review_back'>Back</md-outlined-button>
                                    <md-outlined-button id='btn_review_forward'>Next</md-outlined-button>
                                </div>
                               
                            </div>
                        </div>

                    </section>

                    <section id="applications">
                        <div className="section_cont">
                            <h2>Applications</h2>

                            <div id='items'>
                                <div className='app_item'>
                                    <div className="app_text">
                                        <div className="app_marker">
                                            <p>Research</p>
                                        </div>
                                        <h3>{this.state.applications[0][0]}</h3>
                                        <p>{this.state.applications[0][1]}</p>
                                    </div>
                                    <img alt='A person looking at a car with a check mark above it' src='./assets/homepage/app_research.webp' />
                                </div>

                                <div className='app_item middle'>
                                    <img alt='A person holding a magnifying glass to a webpage with a car on it' src='./assets/homepage/app_inspect.webp' />
                                    <div className="app_text">
                                        <div className="app_marker">
                                            <p>Inspect</p>
                                        </div>
                                        <h3>{this.state.applications[1][0]}</h3>
                                        <p>{this.state.applications[1][1]}</p>
                                    </div>
                                </div>

                                <div className='app_item'>
                                    <div className="app_text">
                                        <div className="app_marker">
                                            <p>Negotiate</p>
                                        </div>
                                        <h3>{this.state.applications[2][0]}</h3>
                                        <p>{this.state.applications[2][1]}</p>
                                    </div>
                                    <img alt='Two people shaking hands with a car in the background' src='./assets/homepage/app_negotiate.webp' />
                                </div>
                            </div>

                        </div>
                    </section>





                    <Footer show_input={true} />
                    <CookiePopup />
                </main>

            </HelmetProvider>

        )
    }
}

export default Homepage;
