import React, { Component } from "react";
import HamburgerMenu from "./Hamburger";
import '../styles/components/header.scss';

class Header extends Component {

    componentDidMount() {
        if (this.props.show_banner) {
            const p1 = document.getElementById('banner_1');
            const p2 = document.getElementById('banner_2');

            function toggleVisibility() {
                p1.classList.toggle('show')
                p2.classList.toggle('show')
            }

            const intervalId = setInterval(toggleVisibility, 5000);
        }
    }

    render() {
        const { active_nav } = this.props;

        return (
            <div id="main_header" style={this.props.dark ? { backgroundColor: '#005047' } : { backgroundColor: 'white' }}>

                <div id="header_content">

                    <img onClick={() => { window.location.href = '/' }} src={this.props.dark ? "./assets/our_logos/logo_white.svg" : "./assets/our_logos/logo_black.svg"} draggable="false" alt="The ARC logo" />

                    <div id='header_right'>
                        {active_nav === 'report' && <div id="report_mob_head_plate">
                            <p>{this.props.plate}</p>
                        </div>}

                        <HamburgerMenu id="report_nav_ham" colour={this.props.dark ? 'white' : "#344054"}>

                            <button
                                onClick={() => { window.location.href = '/' }}
                                className={active_nav === 'home' ? "ham_active ham_btn" : "ham_inactive ham_btn"}>
                                Home
                            </button>

                            {/* <button
                                onClick={() => { window.location.href = '/pricing' }}
                                className={active_nav === 'pricing' ? "ham_active ham_btn" : "ham_inactive ham_btn"}>
                                Pricing
                            </button> */}

                            {/* <button
                                onClick={() => { window.location.href = '/report/1234' }}
                                className={active_nav === 'report' ? "ham_active ham_btn" : "ham_inactive ham_btn"}>
                                Sample Report
                            </button> */}

                            <button
                                onClick={() => { window.location.href = '/about' }}
                                className={active_nav === 'about' ? "ham_active ham_btn" : "ham_inactive ham_btn"}>
                                About
                            </button>

                            <button
                                onClick={() => { window.location.href = '/help' }}
                                className={active_nav === 'help' ? "ham_active ham_btn" : "ham_inactive ham_btn"}>
                                Help
                            </button>

                            <button
                                onClick={() => { window.location.href = '/blog' }}
                                className={active_nav === 'blog' ? "ham_active ham_btn" : "ham_inactive ham_btn"}>
                                Blog
                            </button>
                        </HamburgerMenu>
                    </div>
                </div>

                {this.props.show_banner && <div id='banner'>
                    {/* <p id='banner_1' className="show">Buy a full car check for only £4.99, avoid £1,000s in costly repairs</p>
                    <p id='banner_2'>{window.innerWidth < 460 ? 'New! Try our free car check before you buy.' : 'New! Try our free car check before you buy. Enter your reg number to begin.'}</p> */}
                </div>}

            </div>
        );
    }
}

export default Header;
