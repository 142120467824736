import React, { Component } from "react";

import '../../styles/components/article.scss';
import BlogCTA from '../cards/BlogCTA'

const HEADINGS = ["What-Do-the-Numbers-Say", "The-Best-and-Worst-Cars-of-2023", "Top-MOT-Issues-to-Look-Out-For", "Looking-Towards-the-Future"]

let positions_set = false

class MOT2023 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            scroll_perc: 0,
            active_h: "",
            positions: {
                "What-Do-the-Numbers-Say": 0,
                "The-Best-and-Worst-Cars-of-2023": 0,
                "Top-MOT-Issues-to-Look-Out-For": 0,
                "Looking-Towards-the-Future": 0
            }
        }
    }

    componentDidMount() {
        if (window.innerWidth > 904) {
            this.set_positions(null)
        }
    }

    set_positions(section) {
        let pos_obj = {}

        for (let h of HEADINGS) {
            let elem = document.querySelectorAll(`a[href="#${h}"]`)[0]
            pos_obj[h] = elem.offsetTop
        }

        this.setState({ positions: pos_obj })

        if (section) {
            window.scrollTo(0, pos_obj[section.replaceAll(" ", "-")] - (window.innerHeight / 2) + 2)
        }
    }


    handle_scroll = () => {
        if (!positions_set) {
            this.set_positions(null)
        }

        positions_set = true

        const scroll_location = window.scrollY;
        const total_height = document.documentElement.scrollHeight - window.innerHeight;

        let change_point = window.innerWidth > 1239 ? 765 : 701

        if (scroll_location >= change_point && !document.getElementById('scroll_point_cont').classList.contains('float')) {
            document.getElementById('scroll_point_cont').classList.toggle('float')
        } else if (scroll_location < change_point && document.getElementById('scroll_point_cont').classList.contains('float')) {
            document.getElementById('scroll_point_cont').classList.toggle('float')
        }

        const percentage = (scroll_location / total_height) * 100;

        let visited = []
        let not_visited = []
        const POS = this.state.positions

        for (let h in POS) {
            if (POS[h] - (window.innerHeight / 2) < scroll_location) {
                visited.push(h)
            } else {
                not_visited.push(h)
            }
        }

        let active = visited.pop()
        active = active ? active : HEADINGS[0]

        this.update_scroll_points(visited, not_visited, active)

        this.setState({
            scroll_perc: percentage,
            active_h: active
        });
    };

    update_scroll_points = (visited, not_visited, active) => {
        const POS = this.state.positions
        const KEYS = Object.keys(POS)
        const NEXT = KEYS.indexOf(active) + 1

        const BASE = POS[active] - (window.innerHeight / 2)
        const TARGET = POS[KEYS[NEXT]] - (window.innerHeight / 2)
        const PROG = Math.max(((window.scrollY - BASE) * 100 / (TARGET - BASE)), 0)

        const ACTIVE_POINT = document.querySelectorAll(`[data-key="${active}"]`)[0]
        ACTIVE_POINT.classList.add('active')
        ACTIVE_POINT.querySelector('.inner_bar_progress').style.height = `${PROG}%`


        for (let h of visited) {
            let elem = document.querySelectorAll(`[data-key="${h}"]`)[0]
            elem.classList.remove('active')
            elem.classList.add('visited')
            elem.querySelector('.inner_bar_progress').style.height = '100%'
        }

        for (let h of not_visited) {
            let elem = document.querySelectorAll(`[data-key="${h}"]`)[0]
            elem.classList.remove('active')
            elem.classList.remove('visited')
            elem.querySelector('.inner_bar_progress').style.height = '0'
        }
    }

    handle_scroll_click = (e) => {
        let parent_class = e.target.parentNode.classList
        let this_class = e.target.classList
        let target_div = null

        if (this_class[0] === 'scroll_item') {
            target_div = e.target
        } else if (parent_class[0] === 'scroll_item') {
            target_div = e.target.parentNode
        } else if (this_class[0] !== "inner_bar" && this_class[0] !== "inner_bar_progress") {
            target_div = e.target.parentNode.parentNode
        }

        if (target_div) {
            this.set_positions(target_div.querySelectorAll('p')[0].innerHTML)
        }
    }

    render() {

        if (window.innerWidth > 904) {
            window.addEventListener('scroll', this.handle_scroll)
        }

        const ARTICLE = this.props.article
        return (
            <section className="article">

                <div className="article_opener">
                    <div className="opener_inner">

                        <div className="left">
                            <h1 className="article_h1">{ARTICLE.title}</h1>
                            <p className="article_subtitle">{ARTICLE.subtitle}</p>
                        </div>

                        <img alt={ARTICLE.img_alt} src={ARTICLE.img_src} />
                    </div>
                </div>

                <div className="article_body">
                    <div className="info">
                        <div className="metadata">
                            <p className="meta_author">{ARTICLE.author}</p>
                            <p>{ARTICLE.date}</p>
                            <p>{ARTICLE.read} read</p>
                        </div>
                        <div className="socials">
                            <a href="https://www.instagram.com/xegon_automotive" target="_blank" rel="noreferrer"><img alt="Instagram logo" src="./assets/icons/insta_square.svg" /></a>
                            <a href="https://www.facebook.com/profile.php?id=61550323427271" target="_blank" rel="noreferrer"><img alt="Facebook logo" src="./assets/icons/fb_square.svg" /></a>
                            <a href="https://www.linkedin.com/company/98098382/" target="_blank" rel="noreferrer"><img alt="LinkedIn Logo" src="./assets/icons/linkedin_square.svg" /></a>
                        </div>
                    </div>

                    <div className="content">

                        <p className="intro">
                            Earlier this year, the Driver and Vehicle Licensing Agency (DVLA) released the <a target="_blank" className="outbound" href='https://www.data.gov.uk/dataset/e3939ef8-30c7-4ca8-9c7c-ad9475cc9b2f/anonymised-mot-tests-and-results'>official MOT data</a> for the year, and thanks to some number-crunching by the team at <a target="_blank" className="outbound" href='/'>Auto Reg Check</a>, we can exclusively reveal the car brands and models with the best and worst MOT results for 2023.
                            <br /><br />With nearly 37 million MOT tests carried out, the statistics offer a fascinating glimpse into common MOT failures for motorists to look out for, as well as the most (and least) reliable brands and the overall state of the cars on UK roads.
                        </p>

                        <h2><a href="#What-Do-the-Numbers-Say">What Do the Numbers Say?</a></h2>
                        <p>In total, 36.9M MOT tests were carried out in the UK during 2023.<br /><br />The average first-time MOT pass rate was 70.7%, with 20.9M cars passing the test first-time and 6.82M vehicles failing their MOT. The pass rate for 2023 is the same as 2022 and slightly higher than 2021. 6.2% passed with rectification at the testing station, which means the car failed but was then fixed within 1 hour of the initial test.</p>

                        <div className="single_caption">
                            <img className="img_full img_full_width" src="./assets/blog/mot_2023/pass_rate.webp" alt="A pie chart showing 71% passed first time, 23% failed first time, and 6% passed with rectification at the testing station" />
                            <p>71% of cars passed the MOT test first-time. 6% passed after being fixed within the hour.</p>
                        </div>

                        <h3 className="h3_highlight">Most Tested Manufacturers</h3>
                        <div className="single_caption">
                            <img className="img_full img_full_width" src="./assets/blog/mot_2023/make_count.webp" alt="A bar chart showing Ford had around 5.5M cars tested, Volkswagen and Vauxhall had around 3.75M, and BMW, Nissan, Audi, Toyota, Peugeot, Renault, and Honda all had between around 1M and 2M." />
                            <p>The top 10 manufacturers accounted for a whopping 68% of all MOT tests in 2023.</p>
                        </div>

                        <p>Ford was the most tested manufacturer in 2023, accounting for over 5M tests, with Volkswagen and Vauxhall closely matched for second and third place at around 3.5M each.</p>
                            {/* <BlogCTA n='1' /> */}
                            <p>Ford, Volkswagen, Nissan, Renault and Vauxhall all had the same top reason for failing an MOT - a broken suspension spring. Toyota and Honda shared a top failure of broken headlamps, and Nissan’s was a worn suspension pin. Those all feature in the top MOT failure items below, but Peugoet and BMW had top failures that are not listed in the top 10. Peugeot’s was a faulty CV boot (part of the axle that keeps dirt and moisture out), and BMW’s was a fluid leak in the shock absorbers - possibly something to keep an eye on if you own a car from these manufacturers.</p>

                        <div className="single_caption">
                            <img className="img_full img_full_width" src="./assets/blog/mot_2023/common_models.webp" alt="A bar chart showing the top models of Ford Fiesta, Ford Focus, Vauxhall Corsa, Volkswagen Golf, and Wolkswagen Polo in terms of MOT test count and number of unique vehicles" />
                            <p>Chart showing the number of MOT tests for each model in the database compared with the number of unique cars for that model on the road. The number of tests is higher than the number of cars on the road as one car may require multiple MOT tests in order to pass.</p>
                        </div>
                        <p>Ford also led the top 5 car models in terms of number of MOT tests, with the extremely popular Fiesta and Focus leading the way at the top of the charts, followed by the Vauxhall Corsa and Volkswagen Golf and Polo.
                        </p>

                        <h2><a href="#The-Best-and-Worst-Cars-of-2023">The Best and Worst Cars of 2023</a></h2>
                        <h3 className="h3_highlight">Car models with the best MOT pass rates in 2023*</h3>
                        <ol>
                            <li><b>Ferrari 488</b> (97.9%)</li>
                            <li><b>Volkswagen ID.4</b> (97.1%)</li>
                            <li><b>Ferrari 458</b> (97.0%)</li>
                            <li><b>Lexus UX</b> (96.6%)</li>
                            <li><b>Bentley Bentayga</b> (95.8%)</li>
                            <li><b>Mazda CX-30</b> (95.7%)</li>
                            <li><b>Aston Martin DB11</b> (95.6%)</li>
                            <li><b>Nissan GT-R</b> (95.4%)</li>
                            <li><b>Lexus ES</b> (95.3%)</li>
                            <li><b>Aston Martin DBS</b> (95.2%)</li>
                        </ol>
                        <p>It’s no surprise that a luxury sports car takes the top spot, as they tend to be serviced more frequently and maintained to a far higher standard. The Volkswagen ID.4 is an interesting addition into the top 3. This could be due to it being a new model with an electric drivetrain, which are generally less likely to develop faults thanks to fewer moving parts compared to internal combustion engines.</p>
                        <h3 className="h3_highlight">Car models with the worst MOT pass rates in 2023*</h3>
                        <ol>
                            <li><b>Peugeot 807</b> (63.5%)</li>
                            <li><b>Citroen C8</b> (63.9%)</li>
                            <li><b>Mitsubishi Space Star</b> (63.9%)</li>
                            <li><b>Daewoo Matiz</b> (64.1%)</li>
                            <li><b>Fiat Stilo</b> (64.5%)</li>
                            <li><b>Dodge Caliber</b> (64.6%)</li>
                            <li><b>Jeep Patriot</b> (65.1%)</li>
                            <li><b>Chevrolet Lacetti</b> (65.2%)</li>
                            <li><b>Kia Cerato</b> (66.2%)</li>
                            <li><b>Fiat Multipla</b> (66.4%)</li>

                        </ol>
                        <p>Taking the top spot is the Peugeot 807, a minivan built from 2002 - 2010. Interestingly all of the top 3 are minivans, which suggests this particular body style has a worse MOT pass rate than others.</p>
                       {/* <BlogCTA n='2' /> */}
                        <h3 className="h3_highlight">Manufacturers with the best MOT pass rates in 2023*</h3>
                        <ol>
                            <li><b>Lamborghini</b> (96.8%)</li>
                            <li><b>McLaren</b> (96.2%)</li>
                            <li><b>Ferrari</b> (95.6%)</li>
                            <li><b>Rolls Royce</b> (94.9%)</li>
                            <li><b>Bentley</b> (92.9%)</li>
                            <li><b>Lexus</b> (92.7%)</li>
                            <li><b>Aston Martin</b> (92.2%)</li>
                            <li><b>Polestar</b> (91.3%)</li>
                            <li><b>Morgan</b> (90.5%)</li>
                            <li><b>TVR</b> (89.9%)</li>

                        </ol>

                        <h3 className="h3_highlight">Manufacturers with the worst MOT pass rates in 2023*</h3>
                        <ol>
                            <li><b>Daewoo</b> (65.5%)</li>
                            <li><b>Rover</b> (70.8%)</li>
                            <li><b>Chevrolet</b> (70.8%)</li>
                            <li><b>GWM </b>(71.0%) </li>
                            <li><b>Dodge</b> (71.5%)</li>
                            <li><b>Chrysler</b> (71.7%)</li>
                            <li><b>Daihatsu</b> (73.4%)</li>
                            <li><b>Saab</b> (73.7%)</li>
                            <li><b>Renault</b> (74.0%)</li>
                            <li><b>Citroën</b> (75.6%)</li>

                        </ol>
                        <p>*only including those with more than 1000 MOT tests.</p>
                        <h2><a href="#Top-MOT-Issues-to-Look-Out-For">Top Mot Issues to Look Out For</a></h2>
                        <h3 className="h3_highlight">Advisories</h3>
                        <p>The top advisory (a non-failing issue to be aware of) was insufficient tyre tread depth (8.7M occurrences); followed by tyre damage (4.8M), weakened brake discs (4.6M) and worn brake lining (3.4M) - so make sure to keep an eye on your tyres and brakes!
                        </p>
                        <h3 className="h3_highlight">Failures</h3>
                        <div className="single_caption">
                            <img className="img_full img_full_width" src="./assets/blog/mot_2023/failure_reasons.webp" alt="A bar chart showing the top failure reasons of tread depth, broken springs, worn brakes, faulty lamps, faulty wipers, and worn suspension" />
                            <p>Tyre tread depth leads the way as the most common MOT failure item in 2023.</p>
                        </div>
                        <p>Tread depth again gets the top spot for MOT failures, which is no surprise as it’s one of the few issues that are inevitable no matter what car you drive.</p>
                        <h2><a href="#Looking-Towards-the-Future">Looking Towards the Future</a></h2>
                        <p>Major manufacturers like Ford, Honda, Mercedes-Benz, Toyota, and Volkswagen Group have agreed to share live recall data with the DVSA, ensuring that MOT testing remains robust. Although plans to extend the first MOT to four years have been halted, they remain a possibility for the future. <a target='_blank' className="outbound" href='https://www.gov.uk/government/news/government-committed-to-mot-modernisation-as-consultation-concludes'>The DVLA continues to explore advancements in testing</a>, such as more effective diesel particulate emissions tests and enhancements for electric vehicle MOTs. As the automotive landscape continues to evolve, staying informed and proactive about vehicle health is crucial for road safety and reliability.
                        </p>

                    </div>

                    <div className="nav">
                        <div id="scroll_point_cont" style={{ height: `${HEADINGS.length * 75}px` }}>
                            {HEADINGS.map((h, i) => (
                                <div onClick={this.handle_scroll_click} key={i} data-key={h} className='scroll_item'>
                                    <p>{h.replaceAll("-", " ")}</p>
                                    <div className="scroll_icon">
                                        <div className='inner_circle'></div>
                                        <div className='inner_bar'>
                                            <div className='inner_bar_progress'></div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

            </section>

        );
    }
}
export default MOT2023;