import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom"

import Homepage from './views/Homepage'
import About from './views/About'
import Pricing from './views/Pricing'
import Report from './views/Report'
import NotFound from './views/errors/NotFound'
import Help from './views/Help'
import Buy from './views/Buy';
import Blog from './views/Blog';
// import Feedback from './views/Feedback';
import Confirmation from './components/user_journey/Confirmation';
import ServerError from './views/errors/ServerError';
import Maintenance from './views/errors/Maintenance';
import ReportError from './views/errors/ReportError';
import InvalidPlate from './views/errors/InvalidPlate'
import NoReport from './views/errors/NoReport';
import NewRegistration from './views/errors/NewRegistration';
import TermsAndConditions from './views/docs/TermsAndConditions';
import Privacy from './views/docs/Privacy';
import Cookies from './views/docs/Cookies';

import './styles/index.scss';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Homepage />} />

            <Route path="/mot-check" element={<Homepage />} />
            <Route path="/tax-check" element={<Homepage />} />
            <Route path="/vehicle-check" element={<Homepage />} />
            <Route path="/reg-check" element={<Homepage />} />
            <Route path="/number-plate-check" element={<Homepage />} />

            <Route path="/about" element={<About />} />
            <Route path="/pricing" element={<Maintenance />} />
            <Route path="/help" element={<Help />} />
            {/* <Route path="/buy" element={<Buy />} /> */}
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:article" element={<Blog />} />
            {/* <Route path="/confirmation" element={<Confirmation />} /> */}

            {/* <Route path="/feedback" element={<Feedback />} /> */}
            {/* <Route path="/report/:id" element={<Report free_check={false}/>} /> */}
            {/* <Route path="/free-check/:plate" element={<Report free_check={true}/>} /> */}

            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/cookies-policy" element={<Cookies />} />
            <Route path="/privacy-policy" element={<Privacy />} />

            <Route path="/server-error" element={<ServerError />} />
            <Route path="/report-error" element={<ReportError />} />
            <Route path="/invalid-report" element={<NoReport />} />
            <Route path="/new-registration" element={<NewRegistration />} />
            <Route path="/invalid-plate" element={<InvalidPlate />} />
            <Route path="/maintenance" element={<Maintenance />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    </BrowserRouter>
);
