import React, { Component } from "react";
import '../styles/components/footer.scss';
import PlateInput from "./PlateInput";

class Footer extends Component {
    render() {
        return (
            <div id="main_footer">

                <div id="footer_content">
                    {this.props.show_input && false && <div id="footer_top">

                        <div id='text'>
                            <h3>Get your instant car check today</h3>
                            <p>Comprehensive car checks, delivered by Auto Reg Check</p>
                        </div>

                        {/* <PlateInput input_id='footer_plate_input' /> */}
                    </div>}

                    <div id='footer_bottom' className={this.props.show_input ? '' : 'no_input'}>

                        <p>&copy; 2023 Xegon Automotive. All rights reserved.</p>

                        <div id='socials'>
                            <a href="https://www.linkedin.com/company/98098382/" target="_blank" rel="noreferrer" ><img alt="The LinkedIn Logo" src='./assets/icons/linkedin.svg' /></a>
                            <a href="https://www.facebook.com/profile.php?id=61550323427271" target="_blank" rel="noreferrer"><img alt="The Facebook Logo" src='./assets/icons/fb.svg' /></a>
                            <a href="https://www.instagram.com/xegon_automotive" target="_blank" rel="noreferrer"><img alt="The Instagram Logo" src='./assets/icons/insta.svg' /></a>
                        </div>

                        <div id="docs">
                            <p><a href='/terms-and-conditions' target="_blank">Terms & Conditions</a></p>
                            <p><a href='/privacy-policy' target='_blank'>Privacy Policy</a></p>
                            <p><a href='/cookies-policy' target='_blank'>Cookies</a></p>
                        </div>
                    </div>
                </div>



            </div>
        );
    }
}
export default Footer;